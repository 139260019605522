/* Add Internal Note Styles Start */
.internal-notes-list::-webkit-scrollbar {
  width: 8px;
}

.internal-notes-list::-webkit-scrollbar-thumb {
  background: #a8a8a8;
}

.add-edit-note-form input,
.add-edit-note-form textarea {
  border: none !important;
}

.add-edit-note-form textarea {
  height: 100px;
  resize: none;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}
.upload-list-inline {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}
/* Add & Edit Internal Note Styles End */
