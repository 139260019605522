/* .onboarded-patients-table .ant-pro-table-list-toolbar-container {
  display: block !important;
} */

.onboarded-patients-table .hide-three-dot {
  display: none;
}

.onboarded-patients-table .tableRow:hover {
  .hide-three-dot {
    display: block;
  }
}

.onboarded-patients-table .show-three-dot {
  display: block;
}

.onboarded-patients-table .ant-pagination-total-text {
  margin-right: auto;
}

.onboarded-patients-table .ant-pro-table-list-toolbar-right {
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: flex-start !important;
}

.onboarded-patients-table .ant-pro-table-list-toolbar-setting-item {
  margin-inline: 0;
}

.ant-pro-table-alert {
  display: flex;
  background-color: #fff;
  margin-block-end: 0;
}
